import React, { Component, Fragment } from 'react';

class GradingForm extends Component {
  constructor(props) {
    super(props);

    // Default last visited date to two weeks ago
    // let lastVisitDate = new Date(Date.now() - 12096e5).valueOf();
    // let responsesUsed = [];

    if (localStorage) {
      // lastVisitDate = localStorage.getItem('lastVisitDate');
      // const currentDate = new Date();
      // localStorage.setItem('lastVisitDate', currentDate.valueOf());
      // responsesUsed = localStorage.getItem('responsesUsed');
      // responsesUsed = responsesUsed ? JSON.parse(responsesUsed) : [];
    }

    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    
  }

  handleFieldBlur(e) {
    const { data: { responses }, updateScoreEvent } = this.props;
    const el = e.currentTarget;
    const score = el.value;
    const responseIndex = Number(el.getAttribute('data-index'));
    if (score !== responses[responseIndex][0]) {
      updateScoreEvent(score, responseIndex);
    }
    // this.setState({ ...this.state, response });
  }

  handleFieldChange(e) {
    const { data: { responses }, updateScoreEvent } = this.props;
    const el = e.currentTarget;
    const score = el.value;
    const responseIndex = Number(el.getAttribute('data-index'));
    if (score !== responses[responseIndex][0]) {
      updateScoreEvent(score, responseIndex);
    }

    // this.setState({ ...this.state, holdTimer: new Date(Date.now() - 12096e5).valueOf() })
    // localStorage.setItem('responsesUsed', '[]');
    // this.setState({...this.state, responsesUsed: [] })
  }

  handleTabClick(e) {
    this.props.setActiveCategoryEvent(Number(e.currentTarget.getAttribute('data-index')));
  }

  render() {
    const { data: { responses, name }, activeCategory } = this.props;
    const currentResponses = responses.filter((response, i) => i >= activeCategory * 10 && i < (activeCategory + 1) * 10);

    return (
        <div>
          <form className={'respondForm'} method='post' encType='multipart/form-data' id='respondForm' name='respondForm' action='http://localhost:5015/respond'>
            <legend>{`${name}'s Answers`}</legend>
            <p>Responses will be saved automatically</p>
            <div className={"form-elements"}>
            {currentResponses.map((response, i) => (
              <Fragment key={i}>
                <div className={"form-group scoring-options"}>
                  <input
                    type={"radio"}
                    data-index={String(i + activeCategory * 10)} 
                    checked={response[0] === "1"}
                    value={"1"} 
                    name={`score${i}`}
                    id={`scoreCorrect${i}`}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleFieldBlur}
                  />
                  <label className={"score-label"} htmlFor={`scoreCorrect${i}`}>✔</label>
                  <input
                    type={"radio"}
                    data-index={String(i + activeCategory * 10)} 
                    checked={response[0] === "0.5"}
                    value={"0.5"}
                    name={`score${i}`}
                    id={`scoreHalf${i}`}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleFieldBlur}
                  />
                  <label className={"score-label"} htmlFor={`scoreHalf${i}`}>½</label>
                  <input
                    type={"radio"}
                    data-index={String(i + activeCategory * 10)}
                    checked={response[0] === "0"}
                    value={"0"}
                    name={`score${i}`}
                    id={`scoreWrong${i}`}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleFieldBlur}
                  />
                  <label className={"score-label"} htmlFor={`scoreWrong${i}`}>❌</label>
                  </div>
                  <div key={i} className={"form-group"}>
                    <label className={"response-label"} htmlFor={`response${i}`}>{i + 1 + activeCategory * 10}. </label>
                    <input 
                      className={`answer`} 
                      value={response[1]} 
                      data-index={String(i + activeCategory * 10)} 
                      type={"text"} 
                      id={`response${i}`} 
                      name={"response"} 
                      readOnly 
                    />
                  </div>
                </Fragment>)
              )}
            </div>
          </form>
        </div>
    );
  }
}
export default GradingForm;