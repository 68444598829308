import React, { Component } from 'react';
import { generateRequest } from './xhr';
import {
  BrowserRouter as Router,
  Link,
  withRouter,
} from "react-router-dom";

class HomeLayout extends Component {
  constructor(props) {
    super(props);

    // Default last visited date to two weeks ago
    // let lastVisitDate = new Date(Date.now() - 12096e5).valueOf();

    // if (localStorage) {
    //   lastVisitDate = localStorage.getItem('lastVisitDate');
    //   const currentDate = new Date();
    //   localStorage.setItem('lastVisitDate', currentDate.valueOf());
    // }

    const currentDate = new Date();
    let teamId = "";

    if (localStorage) {
      const createdDateString = localStorage.getItem('teamIdCreated');
      const retreivedTeamId = localStorage.getItem('previousTeamId');
      teamId = (retreivedTeamId && createdDateString === `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`) ? retreivedTeamId : "";
    }

    this.state = {
      teamName: "",
      teamId,
    };

    this.handleTeamIdChange = this.handleTeamIdChange.bind(this);
    this.handleTeamNameChange = this.handleTeamNameChange.bind(this);
    this.handleTeamCreate = this.handleTeamCreate.bind(this);
    
  }

  handleTeamIdChange(e) {
    e.preventDefault();
      this.setState({teamId: e.currentTarget.value});
  }
  handleTeamNameChange(e) {
    e.preventDefault();
    this.setState({ teamName: e.currentTarget.value });
  }
  handleTeamCreate(e) {
    e.preventDefault();

    const { history } = this.props;

    generateRequest('create/team', { name: this.state.teamName }, 'POST').then((parsedResponse) => {

      const currentDate = new Date();

      if (localStorage) {
        localStorage.setItem('previousTeamId', parsedResponse.id);
        localStorage.setItem('teamIdCreated', `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`);
      }

      if (parsedResponse.id) {
        history.push(`/${parsedResponse.id}`);
      }
    });
  }
  
  

  render() {
    const { teamId, teamName } = this.state;

    return (
      <div>
        <form method="post" name="createTeam" action="/create/team">
          <h1>Create a team for today</h1>
          <label htmlFor={"teamName"}>Team Name</label>
          <input value={teamName} onChange={this.handleTeamNameChange} type={"text"} placeholder={"Team Name"} name="teamName" id="teamName" />
          <button className={"join-game"} onClick={this.handleTeamCreate}>Join the game!</button>
        </form>
        <form method="post" name="loadTeam" action="/load/team">
          <h2>Have you already created a team today?</h2>
          <label htmlFor={"teamId"}>Team ID?</label>
          <input value={teamId} onChange={this.handleTeamIdChange} type={"text"} placeholder={"Team ID"} name="teamId" id="teamId" />
          <Link to={`/${this.state.teamId}`}>Jump back into the game</Link>
        </form>
      </div>
    );
  }
}
export default withRouter(HomeLayout);