import React, { Component, Fragment } from 'react';
import ResponseForm from './ResponseForm';
import MemberForm from './MemberForm';
import CategoryTabs from './CategoryTabs';
import { generateRequest } from './xhr';

const getSortedTeams = function(teams) {
  const teamData = teams.slice();
  // const teamData = Object.assign({}, teams);
  return teamData.sort((a,b) => {
    let scoreA = 0;
    let scoreB = 0;
    a.responses.forEach(response => {
      scoreA += Number(response[0]);
    });
    b.responses.forEach(response => {
      scoreB += Number(response[0]);
    });

    if (scoreA > scoreB) {
      return -1;
    } else if (scoreB > scoreA) {
      return 1;
    } else {
      return 0;
    }
  })
}

class DashboardLayout extends Component {
  constructor(props) {
    super(props);

    // Default last visited date to two weeks ago
    // let lastVisitDate = new Date(Date.now() - 12096e5).valueOf();

    if (localStorage) {
      // lastVisitDate = localStorage.getItem('lastVisitDate');
      // const currentDate = new Date();
      // localStorage.setItem('lastVisitDate', currentDate.valueOf());
      // responsesUsed = localStorage.getItem('responsesUsed');
      // responsesUsed = responsesUsed ? JSON.parse(responsesUsed) : [];
    }

    const currentDate = new Date();

    this.state = {
      teams: [],
      date: `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}`,
      activeCategory: 0,
      teamId: "",
      viewMembers: true,
      viewAnswers: false,
      scoreSort: false,
    };

    this.loadPlayerData = this.loadPlayerData.bind(this);
    this.handleDeleteAllTeams = this.handleDeleteAllTeams.bind(this);
    this.handleTeamIdChange = this.handleTeamIdChange.bind(this);
    this.handleDeleteOneTeam = this.handleDeleteOneTeam.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleViewMembers = this.handleViewMembers.bind(this);
    this.handleViewAnswers = this.handleViewAnswers.bind(this);
    this.handleScoreSort = this.handleScoreSort.bind(this);
    
  }

  componentDidMount() {
    this.loadPlayerData();
  }

  handleTeamIdChange(e) {
    e.preventDefault();
    this.setState({ teamId: e.currentTarget.value });
  }
  handleDeleteOneTeam(e) {
    e.preventDefault();

    generateRequest('delete/team', { id: this.state.teamId }, 'POST').then((parsedResponse) => {
      console.log(`Team ${this.state.teamId} deleted`);
    });
  }

  handleViewMembers(e) {
    const { viewMembers } = this.state;
    e.preventDefault();
    this.setState({ viewMembers: !viewMembers });
  }
  handleViewAnswers(e) {
    const { viewAnswers } = this.state;
    e.preventDefault();
    this.setState({ viewAnswers: !viewAnswers });
  }
  handleScoreSort(e) {
    const { scoreSort } = this.state;
    e.preventDefault();
    this.setState({ scoreSort: !scoreSort });
  }
  loadPlayerData() {
    
    const { date } = this.state;
    const currentDate = new Date(date);
    const dateString = `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`;

    generateRequest('load/all', { dateString }, 'POST').then((parsedResponse) => {
      console.log(parsedResponse);
      if (parsedResponse.error === undefined) {
        const teams = Array(parsedResponse);

        this.setState(Object.assign({}, this.state, {teams: teams[0]}));
      }
    });
  }

  handleDeleteAllTeams() {
    if (this.state.teamId !== "delete") return false;
    generateRequest('delete/teams', { id: "666" }, 'POST').then((parsedResponse) => {
      this.setState(Object.assign({}, this.state, {teams: []}));

      // if (parsedResponse.id) {
      //   this.setState({ ...this.state, gradingData: parsedResponse });
      // }
      console.log("all teams deleted");
    });
  }

  handleDateChange(e) {
    e.preventDefault();
    // console.log(e.currentTarget.value);
    // console.log(typeof e.currentTarget.value);
    // `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`
    this.setState({ date: e.currentTarget.value });
  }

  setActiveCategoryEvent(updatedActiveCategory) {
    this.setState({ ...this.state, activeCategory: updatedActiveCategory });
  }

  render() {
    const { teams, teamId, date, viewAnswers, viewMembers, scoreSort } = this.state;

    const teamData = scoreSort ? getSortedTeams(teams) : teams;

    return (
      <div className={"page-view"}>
        <fieldset>
          <label htmlFor={"date"}>Date of trivia:</label>
          <input value={date} onChange={this.handleDateChange} type={"date"} name="date" id="date" />
          <button className={"fetch-score isToggle"} onClick={this.loadPlayerData}>Get Scores</button>
        </fieldset>
        <div>
          <button className={`${viewMembers ? "isPressed" : ""} isToggle view-members`} onClick={this.handleViewMembers}>{`${viewMembers ? "Hide" : "Show"} Members`}</button>
          <button className={`${viewAnswers ? "isPressed" : ""} isToggle view-answers`} onClick={this.handleViewAnswers}>{`${viewAnswers ? "Hide" : "Show"} Answers`}</button>
          <button className={`${scoreSort ? "isPressed" : ""} isToggle score-sort`} onClick={this.handleScoreSort}>{`${scoreSort ? "Unsort" : "Sort"} Scores`}</button>
        </div>
        {teamData.map((team, i) => {
          let totalScore = 0;
          team.responses.forEach(response => {
            totalScore += Number(response[0]);
          });
          return (
            <div key={i}>
              <h2>{team.name} - {team.id}</h2>
              {viewMembers && (
                <Fragment>
                  <h3>Members</h3>
                  <ol>
                    {team.members.map((member, i) => <li key={i}>{member}</li>)}
                  </ol>
                </Fragment>
              )}
              {team.lockedCategories && (
                <Fragment>
                  <h3>Locked Categories</h3>
                  <ol>
                    <li className={`${team.lockedCategories.includes(0) ? "locked" : "unlocked"}`}>{team.lockedCategories.includes(0) ? "Locked" : "Unlocked"}</li>
                    <li className={`${team.lockedCategories.includes(1) ? "locked" : "unlocked"}`}>{team.lockedCategories.includes(1) ? "Locked" : "Unlocked"}</li>
                    <li className={`${team.lockedCategories.includes(2) ? "locked" : "unlocked"}`}>{team.lockedCategories.includes(2) ? "Locked" : "Unlocked"}</li>
                  </ol>
                </Fragment>
              )}
              {viewAnswers && (
                <Fragment>
                  <h3>Responses</h3>
                  <ol>
                    {team.responses.map((response, i) => <li key={i}>{response[0]} - {response[1]}</li>)}
                  </ol>
                </Fragment>
              )}
              <p>Total: {totalScore}</p>
            </div>
          )
        })}
        <div>
          <label htmlFor={"teamId"}>Team ID</label>
          <input value={teamId} onChange={this.handleTeamIdChange} type={"text"} placeholder={"Team ID"} name="teamId" id="teamId" />
          <button className={"delete-one isToggle"} onClick={this.handleDeleteOneTeam}>Delete One Team</button>
        </div>
        <div>
          <button className={"delete-all isToggle"} onClick={this.handleDeleteAllTeams}>Delete all teams</button>
        </div>
      </div>
    );
/*
    return (
      <div className={"page-view"}>
        {this.props.playerId ?
          this.renderPlayerView()
          :
          <p>No ID provided</p>
        }
        <div className={"flow-controls"}>
          <button className={"lock-answers"} disabled={lockedCategories.includes(activeCategory)} onClick={this.lockCategory}>Lock in Category</button>
          {gradingData === null && <div className={"score-button-group"}><button disabled={!lockedCategories.includes(activeCategory)} className={"start-score"} onClick={this.loadGradingData}>Get sheet to score</button><span className={"warning-text"}>Please do not click until all teams are locked in</span></div>}
          {gradingData !== null && <button className={"submit-score"} onClick={this.saveGradingData}>Submit Scores</button>}
          {gradingData === null && <button className={"fetch-score"} onClick={this.loadPlayerData}>Fetch my score</button>}
          {gradingData === null && <button className={"fetch-score"} onClick={this.loadPlayerData}>Fetch my score</button>}
          <button className={"delete-all"} onClick={this.handleDeleteAllTeams}>Delete all teams</button>
        </div>
      </div>
    );
    */
  }
}
export default DashboardLayout;