import React, { Component } from 'react';

class CategoryTabs extends Component {
  constructor(props) {
    super(props);

    this.handleTabClick = this.handleTabClick.bind(this);
    
  }


  handleTabClick(e) {
    this.props.setActiveCategoryEvent(Number(e.currentTarget.getAttribute('data-index')));
  }

  render() {
    const { activeCategory } = this.props;

    return (
      <div className={'tabs'}>
        <button className={'tab'} data-index={0} disabled={activeCategory === 0} onClick={this.handleTabClick}>Category 1</button>
        <button className={'tab'} data-index={1} disabled={activeCategory === 1} onClick={this.handleTabClick}>Category 2</button>
        <button className={'tab'} data-index={2} disabled={activeCategory === 2} onClick={this.handleTabClick}>Category 3</button>
      </div>
    );
  }
}
export default CategoryTabs;