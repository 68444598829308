import React, { Component, Fragment } from 'react';

class ResponseForm extends Component {
  constructor(props) {
    super(props);

    // Default last visited date to two weeks ago
    // let lastVisitDate = new Date(Date.now() - 12096e5).valueOf();
    // let responsesUsed = [];

    if (localStorage) {
      // lastVisitDate = localStorage.getItem('lastVisitDate');
      // const currentDate = new Date();
      // localStorage.setItem('lastVisitDate', currentDate.valueOf());
      // responsesUsed = localStorage.getItem('responsesUsed');
      // responsesUsed = responsesUsed ? JSON.parse(responsesUsed) : [];
    }

    this.state = {
      showGrades: false
    }

    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    
  }

  componentDidUpdate(props, prevProps) {
    const { responses, activeCategory } = this.props;
    const { showGrades } = this.state;
    const scoredActiveResponse = responses.find((response, i) => i >= activeCategory * 10 && i < (activeCategory + 1) * 10 && response[0] !== "0");
    
    if (scoredActiveResponse !== undefined && !showGrades) {
      this.setState({ ...this.state, showGrades: true });
    } else if (scoredActiveResponse === undefined && showGrades) {
      this.setState({ ...this.state, showGrades: false });
    }
  }

  handleFieldBlur(e) {
    const { responses, updateResponseEvent } = this.props;
    const el = e.currentTarget;
    const response = el.value;
    const responseIndex = Number(el.getAttribute('data-index'));

    updateResponseEvent(response, responseIndex, true);
  }

  handleFieldChange(e) {
    const { updateResponseEvent } = this.props;
    const el = e.currentTarget;
    const response = el.value;
    const responseIndex = Number(el.getAttribute('data-index'));
    updateResponseEvent(response, responseIndex, false);

    // this.setState({ ...this.state, holdTimer: new Date(Date.now() - 12096e5).valueOf() })
    // localStorage.setItem('responsesUsed', '[]');
    // this.setState({...this.state, responsesUsed: [] })
  }

  render() {
    const { responses, activeCategory, pendingStatuses, lockedCategories } = this.props;
    const { showGrades } = this.state;
    const currentResponses = responses.filter((response, i) => i >= activeCategory*10 && i < (activeCategory+1)*10 );

    return (
        <div>
          <form className={'respondForm'} method='post' encType='multipart/form-data' id='respondForm' name='respondForm' action='http://localhost:5015/respond'>
            <legend>Category {activeCategory+1} Answers</legend>
            <p>Responses will be saved automatically</p>
            <div className={"form-elements"}>
              {currentResponses.map((response, i) => (
                <div key={i} className={"form-group"}>
                  <label className={"response-label"} htmlFor={`response${i}`}>{i + 1 + activeCategory * 10}. </label>
                  <input 
                    className={`${pendingStatuses.includes(i + activeCategory * 10) ? "pending-status" : ""}`} 
                    value={response[1]} 
                    data-index={String(i + activeCategory * 10)} 
                    type={"text"} id={`response${i}`} 
                    name={"response"} 
                    readOnly={lockedCategories.includes(activeCategory)}
                    onChange={this.handleFieldChange} 
                    onBlur={this.handleFieldBlur} 
                  />
                  {showGrades && (<div className={"score-wrapper"}>
                    {response[0] === "1" && <span>✔</span>}
                    {response[0] === "0.5" && <span>½</span>}
                    {response[0] === "0" && <span>❌</span>}
                  </div>)}
                </div>)
              )}
            </div>
          </form>
        </div>
    );
  }
}
export default ResponseForm;