import React, { Component, Fragment } from 'react';

let serverPath = 'http://localhost:5015/';
if (window.location) {
  if (!window.location.host.includes('localhost')) serverPath = `${window.location.origin}/trivia/`;
}

// Prepare our AJAX request
const generateRequest = (requestType, playerId) => {
  const currentForm = document.forms[`${requestType}Form`];
  const formData = new FormData(currentForm);
  formData.append("playerId", playerId);

  return new Promise((resolve, reject) => {
    var updateCounterXHTTP = new XMLHttpRequest();
    updateCounterXHTTP.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        if (this.response.charAt(0) === '{' || this.response.charAt(0) === '[') {
          resolve(JSON.parse(this.response));
        } else {
          resolve();
        }
      } else if (this.status !== 200 && this.status !== 0) {
        reject()
      } else {
        // console.log(this.response);
      }
    };

    updateCounterXHTTP.open('POST', `${serverPath}${requestType}`, true);
    updateCounterXHTTP.send(formData);
  })
}

class MemberForm extends Component {
  constructor(props) {
    super(props);

    // Default last visited date to two weeks ago
    // let lastVisitDate = new Date(Date.now() - 12096e5).valueOf();
    // let responsesUsed = [];

    if (localStorage) {
      // lastVisitDate = localStorage.getItem('lastVisitDate');
      // const currentDate = new Date();
      // localStorage.setItem('lastVisitDate', currentDate.valueOf());
      // responsesUsed = localStorage.getItem('responsesUsed');
      // responsesUsed = responsesUsed ? JSON.parse(responsesUsed) : [];
    }

    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);

  }

  respondFormSubmit(e) {
    e.preventDefault();
    const { response } = this.state;

    if (response !== null) {
      const responsesUsed = this.state.responsesUsed.slice();
      responsesUsed.push(response);
      localStorage.setItem('responsesUsed', JSON.stringify(responsesUsed));
      generateRequest("respond", this.props.playerId).then((parsedResponse) => {
        this.setState({ ...this.state, response: null, responsesUsed });
        console.log(parsedResponse);
      })
    }
  }

  handleFieldBlur(e) {
    const { members, updateMemberEvent } = this.props;
    const el = e.currentTarget;
    const member = el.value;
    const memberIndex = Number(el.getAttribute('data-index'));
    updateMemberEvent(member, memberIndex, true);
    // this.setState({ ...this.state, response });
  }

  handleFieldChange(e) {
    const { updateMemberEvent } = this.props;
    const el = e.currentTarget;
    const member = el.value;
    const memberIndex = Number(el.getAttribute('data-index'));
    updateMemberEvent(member, memberIndex, false);

    // this.setState({ ...this.state, holdTimer: new Date(Date.now() - 12096e5).valueOf() })
    // localStorage.setItem('membersUsed', '[]');
    // this.setState({...this.state, membersUsed: [] })
  }

  handleTabClick(e) {
    this.props.setActiveCategoryEvent(Number(e.currentTarget.getAttribute('data-index')));
  }

  render() {
    const { members, name } = this.props;

    return (
      <div>
        <form className={'memberForm'} method='post' encType='multipart/form-data' id='memberForm' name='memberForm' action='http://localhost:5015/respond'>
          <legend>{`${name} Members`}</legend>
          <p>Member names will be saved automatically</p>
          <div className={"form-elements"}>
            {members.map((member, i) => (
              <div key={i} className={"form-group"}>
                <label className={"response-label"} htmlFor={`member${i}`}>{i + 1}. </label>
                <input
                  className={`member-input`}
                  value={member}
                  data-index={String(i)}
                  type={"text"} 
                  id={`member${i}`}
                  name={`member${i}`}
                  onChange={this.handleFieldChange}
                  onBlur={this.handleFieldBlur}
                />
              </div>)
            )}
          </div>
        </form>
      </div>
    );
  }
}
export default MemberForm;