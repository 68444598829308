let serverPath = 'http://localhost:5015/';
if (window.location) {
    if (!window.location.host.includes('localhost')) serverPath = `${window.location.origin}/trivia/`;
}
// Prepare our AJAX request
export const generateRequest = (requestType, data, method) => {
    const placeHolderForm = document.createElement('form');
    placeHolderForm.setAttribute('encType', 'multipart/form-data');
    placeHolderForm.setAttribute('name', 'dataForm');
    const formData = new FormData(placeHolderForm);
    formData.append("data", JSON.stringify(data));
    const methodToUse = method || 'GET';

    return new Promise((resolve, reject) => {
        var updateCounterXHTTP = new XMLHttpRequest();
        updateCounterXHTTP.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                if (this.response.charAt(0) === '{' || this.response.charAt(0) === '[') {
                    resolve(JSON.parse(this.response));
                } else {
                    resolve();
                }
            } else if (this.status !== 200 && this.status !== 0) {
                reject()
            } else {
                // console.log(this.response);
            }
        };

        updateCounterXHTTP.open(methodToUse, `${serverPath}${requestType}`, true);
        updateCounterXHTTP.send(formData);
    })
}