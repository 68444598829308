import React from "react";
// import { userData } from "./data";
import userData from "./data.mjs" 
import Player from "./Player";
import DashboardLayout from "./DashboardLayout";
import HomeLayout from "./HomeLayout";
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {

  return (
    <Router>
      <div>

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/:playerId">
            <PlayerSetup />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div className={"page-view"}>
      <HomeLayout />
    </div>
  );
}

function PlayerSetup() {
  let { playerId } = useParams();

  return (
    <div className={"page-view"}>
      <Player playerId={playerId} />
    </div>
  );
}


function Dashboard() {
  return (
    <div className={"page-view"}>
      <h2>Dashboard</h2>
      <DashboardLayout />
    </div>
  );
}
