import React, { Component, Fragment } from 'react';
import ResponseForm from './ResponseForm';
import GradingForm from './GradingForm';
import MemberForm from './MemberForm';
import CategoryTabs from './CategoryTabs';
import { generateRequest } from './xhr';

const getBlankPlayerData = function() {
  return {
    name: "",
    members: [
      "",
      "",
      "",
      "",
      "",
    ],
    responses: [
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
      [0, ""],
    ]
  }
}


class Player extends Component {
  constructor(props) {
    super(props);

    // Default last visited date to two weeks ago
    // let lastVisitDate = new Date(Date.now() - 12096e5).valueOf();

    if (localStorage) {
      // lastVisitDate = localStorage.getItem('lastVisitDate');
      // const currentDate = new Date();
      // localStorage.setItem('lastVisitDate', currentDate.valueOf());
      // responsesUsed = localStorage.getItem('responsesUsed');
      // responsesUsed = responsesUsed ? JSON.parse(responsesUsed) : [];
    }

    const { responses, members, name } = getBlankPlayerData();
    const currentDate = new Date();

    this.state = {
      responses,
      pendingStatuses: [],
      members,
      name,
      date: `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`,
      gradingData: null,
      saveSuccessful: false,
      submitSuccessful: false,
      activeCategory: 0,
      lockedCategories: []
    };
    
    this.updateMemberEvent = this.updateMemberEvent.bind(this);
    this.updateResponseEvent = this.updateResponseEvent.bind(this);
    this.saveResponse = this.saveResponse.bind(this);
    this.saveMember = this.saveMember.bind(this);
    this.loadPlayerData = this.loadPlayerData.bind(this);
    this.setActiveCategoryEvent = this.setActiveCategoryEvent.bind(this);
    this.loadGradingData = this.loadGradingData.bind(this);
    this.saveGradingData = this.saveGradingData.bind(this);
    this.updateScoreEvent = this.updateScoreEvent.bind(this);
    this.lockCategory = this.lockCategory.bind(this);
    
    this.handleDeleteAllTeams = this.handleDeleteAllTeams.bind(this);
    
    
    
    // this.changeTimer = window.setInterval();
    
  }

  componentDidMount() {
    this.loadPlayerData();
  }

  loadPlayerData() {
    const id = this.props.playerId;
    console.log(id);
    generateRequest('load/team', { id }, 'POST').then((parsedResponse) => {
      console.log(parsedResponse);
      if (parsedResponse.error === undefined) {
        const teamData = {
          responses: parsedResponse.responses,
          members: parsedResponse.members,
          name: parsedResponse.name,
          date: parsedResponse.date,
        };

        this.setState(Object.assign({}, this.state, teamData));
      }
    });
  }

  loadGradingData() {
    generateRequest('load/grading', { dateString: this.state.date, id: this.props.playerId }, 'POST').then((parsedResponse) => {

      if (parsedResponse.id) {
        this.setState({...this.state, gradingData: parsedResponse});
      }
    });
  }

  handleDeleteAllTeams() {
    generateRequest('delete/teams', { id: this.props.playerId }, 'POST').then((parsedResponse) => {

      // if (parsedResponse.id) {
      //   this.setState({ ...this.state, gradingData: parsedResponse });
      // }
      console.log("all teams deleted");
    });
  }

  saveGradingData() {
    generateRequest('save/grading', { id: this.state.gradingData.id, responses: this.state.gradingData.responses }, 'POST').then((parsedResponse) => {
      // TODO check for success
      if (parsedResponse.error === undefined) {
        this.setState({ ...this.state, gradingData: null });
      }
    
    });
  }

  saveResponse(responses, index) {
    generateRequest('save/responses', { responses, id: this.props.playerId }, 'POST').then((parsedResponse) => {
      console.log(parsedResponse);
      const pendingStatuses = this.state.pendingStatuses.slice();
      pendingStatuses.splice(pendingStatuses.findIndex(pendingIndex => pendingIndex === index), 1);

      this.setState({ ...this.state, pendingStatuses });
      console.log(`Cleared pending at ${index}`);
    });
  }

  saveMember(members, index) {
    // const responsesUsed = this.state.responsesUsed.slice();
    // responsesUsed.push(response);
    // localStorage.setItem('responsesUsed', JSON.stringify(responsesUsed));
    generateRequest('save/members', { members, id: this.props.playerId }, 'POST').then((parsedResponse) => {
      console.log(parsedResponse);
      // const pendingStatuses = this.state.pendingStatuses.slice();
      // pendingStatuses.splice(pendingStatuses.findIndex(pendingIndex => pendingIndex === index), 1);

      // this.setState({ ...this.state, pendingStatuses });
      console.log(`Saved ${members}`);
    });
  }

  lockCategory() {
    const lockedCategories = this.state.lockedCategories.slice();
    lockedCategories.push(this.state.activeCategory);
    generateRequest('save/categories', { lockedCategories, id: this.props.playerId }, 'POST').then((parsedResponse) => {
      // console.log(parsedResponse);
      // const pendingStatuses = this.state.pendingStatuses.slice();
      // pendingStatuses.splice(pendingStatuses.findIndex(pendingIndex => pendingIndex === index), 1);

      // this.setState({ ...this.state, pendingStatuses });
      this.setState({ ...this.state, lockedCategories })
      console.log(`Locked ${lockedCategories}`);
    });
  }

  updateResponseEvent(response, index, shouldSave) {
    const responses = this.state.responses.slice();
    const pendingStatuses = this.state.pendingStatuses.slice();
    responses[index][1] = response;
    if (!pendingStatuses.includes(index)) {
      pendingStatuses.push(index);
    }
    
    this.setState({ ...this.state, responses, pendingStatuses});
    if (shouldSave) {
      this.saveResponse(responses, index);
    }
    console.log(`Updating: "${response}" at ${index}`);
  }

  updateMemberEvent(member, index, shouldSave) {
    const members = this.state.members.slice();
    members[index] = member;

    if (shouldSave) {
      this.saveMember(members, index);
    }
    this.setState({ ...this.state, members });

    console.log(`Updating: "${member}" at ${index}`);
  }

  updateScoreEvent(score, index) {
    const responses = this.state.gradingData.responses.slice();
    responses[index][0] = score;
    const updatedGradingData = {...this.state.gradingData, responses };

    this.setState({ ...this.state, gradingData: updatedGradingData });
    console.log(`Updated Scores: ${responses}`);
  }

  setActiveCategoryEvent(updatedActiveCategory) {
    this.setState({ ...this.state, activeCategory: updatedActiveCategory});
  }

  renderPlayerView() {
    const { responses, members, activeCategory, pendingStatuses, name, gradingData, lockedCategories } = this.state;
    // const playerData = userData.find(user => playerId === user.id);
    return (
      <div className={"form-view"}>
        {gradingData === null ?
          (<Fragment>
            <MemberForm members={members} name={name} updateMemberEvent={this.updateMemberEvent} />
            <CategoryTabs activeCategory={activeCategory} setActiveCategoryEvent={this.setActiveCategoryEvent} />
            <ResponseForm lockedCategories={lockedCategories} responses={responses} activeCategory={activeCategory} pendingStatuses={pendingStatuses} updateResponseEvent={this.updateResponseEvent} />
          </Fragment>)
          :
          (<Fragment>
            <CategoryTabs activeCategory={activeCategory} setActiveCategoryEvent={this.setActiveCategoryEvent} />
            <GradingForm 
              data={gradingData} 
              activeCategory={activeCategory} 
              updateScoreEvent={this.updateScoreEvent} 
            />
          </Fragment>)
        }
      </div>
    );
  }

  render() {
    const { gradingData, lockedCategories, activeCategory } = this.state;
    
    return (
      <div className={"page-view"}>
        {this.props.playerId ?
          this.renderPlayerView()
          :
          <p>No ID provided</p>
        }
        <div className={"flow-controls"}>
          <button className={"lock-answers"} disabled={lockedCategories.includes(activeCategory)} onClick={this.lockCategory}>Lock in Category</button>
          {gradingData === null && <div className={"score-button-group"}><button disabled={!lockedCategories.includes(activeCategory)} className={"start-score"} onClick={this.loadGradingData}>Get sheet to score</button><span className={"warning-text"}>Please do not click until all teams are locked in</span></div>}
          {gradingData !== null && <button className={"submit-score"} onClick={this.saveGradingData}>Submit Scores</button>}
          {gradingData === null && <button className={"fetch-score"} onClick={this.loadPlayerData}>Fetch my score</button>}
        </div>
      </div>
    );
  }
}
export default Player;